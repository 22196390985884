import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 94.7vh;

  padding: 10px 20px;

  display: flex;
  flex-direction: column;

  align-items: center;

  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  html {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  height: fit-content;

  display: flex;
  flex-direction: row;

  align-items: center;

  > * {
    flex: 1;

    display: flex;
    flex-direction: row;

    margin: 0px;

    gap: 10px;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 620px) {
      justify-content: center;
      flex-direction: column;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const Button = styled.button`
  max-width: 200px;
  width: 100%;
  height: 40px;

  padding: 5px;

  cursor: pointer;

  border-radius: 8px;
  font-weight: bolder;
  text-transform: uppercase;

  color: #fff;
  background: var(--red-color);

  &:hover {
    opacity: 90%;
    transform: scale(1.05);
  }
`;

export const TableContainer = styled.table`
  width: 100%;
  height: 80%;

  margin: auto;

  display: flex;
  flex-direction: column;

  align-items: start;
  justify-content: start;

  overflow-y: auto;
  overflow-x: auto;

  text-align: center;

  thead {
    min-width: 1000px;

    width: 100%;
    height: fit-content;

    text-transform: uppercase;

    background: var(--red-color);

    tr {
      width: 100%;
      height: 55px;

      display: flex;
      flex-direction: row;
      align-items: center;

      td {
        flex: 1;

        font-size: 18px;
        font-weight: bolder;

        color: #fff;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  tbody {
    min-width: 1000px;

    width: 100%;
    height: fit-content;

    tr {
      width: 100%;
      height: 40px;

      display: flex;
      flex-direction: row;
      align-items: center;

      cursor: pointer;

      border-bottom: 0.5px solid lightgray;

      will-change: transform;
      transition: all 0.3s linear;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        filter: brightness(90%);
        background: #e6e7e9;
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
      }

      td {
        flex: 1;

        font-size: 14px;

        img {
          padding: 2px;
          border-radius: 5px;

          &:hover {
            background-color: #d3d3d3;
          }
        }
      }
    }
  }
`;
