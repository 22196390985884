import * as React from "react";
import { Container, Input, Label } from "./style";
import "./style.css";

const InputLabel = (props) => {
  return (
    <Container className="inputLabel">
      <Input id={props.label} {...props} />
      <Label htmlFor={props.label}>{props.label}</Label>
    </Container>
  );
};

export default InputLabel;
