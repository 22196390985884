import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  overflow-y: auto;
  position: fixed;
  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
`;

export const Titulo = styled.h1`
  font-weight: bolder;
  text-align: center;
  padding-bottom: 10px;
  legend {
    color: black;
    font-size: 25px;
  }
  @media (max-width: 1127px) {
    font-size: 25px;
    text-align: center;
  }
`;

export const ContentForm = styled.div`
  width: 48%;
  min-height: 304.3px;
  max-height: 860px;
  background: #fff;
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  margin-top: 1%;
  margin-bottom: 1%;

  @media (max-width: 1499px) {
    min-height: 485px;
  }
  @media (max-width: 1388px) {
    min-width: 223px;
    width: 62%;
  }
  @media (max-width: 1000px) {
    max-height: 99999px;
  }
  @media (max-width: 746px) {
    width: 88%;
    margin-top: 3%;
    margin-bottom: 5%;
  }
  @media (max-width: 320px) {
    width: 96%;
  }
`;

export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FormEsquerda = styled.div`
  position: relative;
  width: 50%;
  margin-right: 25px;

  /* border: 1px solid black; */

  @media (max-width: 1499px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media (max-width: 1000px) {
    margin-right: 0;
    margin-bottom: 50px;
    margin-top: 15px;
  }
`;

export const FormDireita = styled.div`
  position: relative;
  width: 50%;

  @media (max-width: 1499px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const FormController = styled.div`
  margin-top: 30px;
  padding-right: 0px;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* border: 1px solid black; */

  @media (max-width: 1634px) {
    width: 100%;
    padding-right: 0;
  }
`;

export const Hr = styled.div`
  background-color: red;
  height: 1.2px;
  width: 60%;
  border-radius: 30px;
  margin-top: 5px;
  margin-left: 5px;
`;

export const Div = styled.input`
  float: right;
  display: flex;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 270px;
  text-align: center;
  font-weight: 600;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 11.9px;
  margin-right: 19px;
  margin-top: 0;

  @media (max-width: 1634px) {
    margin-right: 5px;
  }
`;

export const Select = styled.select`
  float: right;
  display: flex;
  background-color: #f3f3f3;
  border: none;
  border-radius: 5px;
  height: 30px;
  width: 270px;
  text-align: center;
  font-weight: 600;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 11.9px;
  margin-right: 19px;
  margin-top: 0;

  @media (max-width: 1634px) {
    margin-right: 5px;
  }
`;

export const BUTTON = styled.div`
  display: flex;
  justify-content: center;
  &:hover {
    opacity: 90%;
    transform: scale(1.2);
    cursor: pointer;
  }
`;

export const Label = styled.label`
  padding-right: 12px;
  @media (max-width: 1499px) {
    padding: 0;
  }
`;

export const Link = styled(Rota)`
  text-decoration: none;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-top: 0.8rem;
  font-weight: bolder;

  background: var(--red-color);
  padding: 8px;
  border-radius: 11.2px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.8s linear;

  &:hover {
    color: whitesmoke;
    transform: scale(1.1);
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    margin-top: 20px;
    background: var(--red-color);
    border: 0;

    padding: 0.6rem 0.5rem;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
  }
`;

export const DivBotao = styled.div`
  /* border: 1px solid black; */
  margin-top: 5%;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1499px) {
    margin-top: 3%;
  }
`;

export const DivCentral = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  @media (max-width: 1000px) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
