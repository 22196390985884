import React from "react";
import {
  Container,
  Selector,
  Input,
  ButtonRight,
  ButtonLeft,
  SerchArea,
} from "./styles";
import { IoMdClose } from "react-icons/io";
import InputMask from "react-input-mask";
import "./styles.css";

export default function Switch({
  text1,
  text2,
  selected,
  setSelected,
  placeholder1,
  placeholder2,
  search,
  setSearch,
}) {
  const handleButtonClick = (index) => {
    setSelected(index);
    setSearch("");
  };

  return (
    <Container>
      <Selector
        style={{ transform: `translateX(${selected === 0 ? "0%" : "103%"})` }}
      >
        DOAÇÃO
      </Selector>
      <ButtonLeft
        onClick={() => handleButtonClick(0)}
        style={{ color: selected === 0 ? "white" : "black" }}
      >
        {text1}
      </ButtonLeft>
      <ButtonRight
        onClick={() => handleButtonClick(1)}
        style={{ color: selected === 1 ? "white" : "black" }}
      >
        {text2}
      </ButtonRight>
      <SerchArea>
        {selected === 0 ? (
          <InputMask
            type="text"
            mask="999.999.999-99"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="mask"
            placeholder={placeholder1}
          />
        ) : (
          <Input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={placeholder2}
          />
        )}
        <IoMdClose color="red" onClick={() => setSearch("")} />
      </SerchArea>
    </Container>
  );
}
