import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { format } from "date-fns";

import {
  Background,
  CompromissoContainer,
  Container,
  Footer,
  HorariosContainer,
} from "./style.js";

import api from "../../services/api.js";
import Auth from "../../services/auth.js";

import RadioButton from "../../Components/RadioButton/index";

import local from "../../Assets/Images/location.png";

import Swal from "sweetalert2";
import InputMask from "react-input-mask";

const MeuAgendamento = () => {
  const history = useHistory();
  const user = Auth.user();

  const [loading, setLoading] = useState(false);

  const [possuiAviso, setPossuiAviso] = useState(true);
  const [possuiAgendamento, setPossuiAgendamento] = useState();

  const [dataDoacao, setDataDoacao] = useState();
  const [idCalendario, setIdCalendario] = useState();
  const [horariosCalendario, setHorariosCalendario] = useState([]);

  const [data, setData] = useState([]);

  const fetchDataAviso = useCallback((cpf) => {
    setLoading(true);
    try {
      api.get(`/show-alerts?cpf_doador=${cpf}`).then((response) => {
        if (
          response?.data?.length <= 0 ||
          response?.data?.mensagem ===
            "O(s) Aviso(s) não foram encontrados ou já foram visualizados."
        ) {
          setPossuiAviso(false);
          setPossuiAgendamento(true);
        } else {
          Swal.fire({
            title: "Aviso",
            html: `
              ${
                response?.data[0].descricao1 &&
                `<p>${response?.data[0].descricao1}</p>`
              } 
              ${
                response?.data[0].descricao2 &&
                `<p>${response?.data[0].descricao2}</p>`
              }
            `,
            confirmButtonText: "OK",
            confirmButtonColor: "var(--red-color)",
          }).then((action) => {
            if (action) {
              const Ids = response?.data[0].id + " " + cpf;
              api
                .post(`/insert-alerts-viewed?ids=${Ids}`)
                .then(() => fetchDataAviso(cpf));
            }
          });
        }
      });
    } catch (error) {
      setPossuiAviso(false);
      setPossuiAgendamento(true);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDataAgendamento = useCallback((cpf) => {
    setLoading(true);
    try {
      api
        .get(`/scheduling?cpf=${cpf}`)
        .then((response) => {
          if (response?.data.length === 0) {
            setPossuiAgendamento(false);
            setData([]);
          } else {
            setPossuiAgendamento(true);
            setData(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchDataCalendarioDoacoes = useCallback(() => {
    setLoading(true);
    try {
      api
        .get(`/calendars?open=${true}`)
        .then((response) => {
          if (response.data.error) {
            Swal.fire({
              title: "Atenção!",
              icon: "warning",
              html: `
                <h4>
                  O cadastro no sistema para a retirada da senha on-line estará disponivel 
                  na quinta-feira (a partir das 9h) que antecede a data da doação de
                  Sangue
                </h4>
                <h4>Acompanhe pelo calendário de Doação de Sangue ${new Date().getFullYear()} no link abaixo:</h4>
                <a href="https://www.fiec.com.br/site/noticia/2088/ver.do" target="_blank">Link do Calendário</a>
              `,
            }).then(() => {
              history.push({ pathname: "/" });
            });
          } else {
            let possuiHorarios = false;
            response?.data?.calendarios?.forEach((calendario) => {
              calendario?.horarios?.forEach((horario) => {
                if (horario?.vagas > horario?.vagas_preenchidas) {
                  possuiHorarios = true;
                }
              });
            });

            setDataDoacao(response?.data?.calendarios[0]?.data_doacao);
            setIdCalendario(response?.data?.calendarios[0]?.id);
            setHorariosCalendario(response?.data?.calendarios[0]?.horarios);

            if (!possuiHorarios) {
              Swal.fire({
                title: "Aviso",
                html: `
                  <h2>Horários Esgotados</h2> 
                  <p>ATENÇÃO, apesar de as senhas terem esgotado é possível que algum doador realize o cancelamento do agendamento. Por isso, acompanhe as senhas disponíveis até o horário limite da inscrição.</p>
                  <a href="https://www.fiec.com.br/site/noticia/2088/ver.do" target="_blank">Confira o calendário de doação</a>
                `,
                confirmButtonText: "OK",
                confirmButtonColor: "var(--red-color)",
              }).then((action) => {
                if (action) {
                  history.push({ pathname: "/" });
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "Falha!",
            icon: "error",
            text: "Falha na busca do calendário!",
            timer: 2000,
          }).then(() => {
            history.push({ pathname: "/" });
          });
        });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [history]);

  async function handleCancel(id, cpf, horario) {
    try {
      if (new Date(dataDoacao) === new Date() && new Date().getHours >= 9) {
        Swal.fire({
          title: "Aviso",
          html: `<p>Não é permitido cancelar o agendamento depois das 9h do dia doação</p>`,
          confirmButtonText: "OK",
          confirmButtonColor: "var(--red-color)",
        });
      }

      Swal.fire({
        title:
          "ATENÇÃO, sua doação de sangue é muito importante pois pode salvar até quatro vidas!!! Tem certeza que deseja cancelar?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonColor: "var(--red-color)",
        denyButtonColor: "var(--red-color)",
        confirmButtonText: "Sim",
        denyButtonText: "Não",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api.get(
            `/cancel-scheduling?id=${id}&cpf=${cpf}&horario=${horario}&cpf_usuario=${user?.cpf}`
          );
          Swal.fire({
            text: `Se necessário agende um novo horário!`,
            icon: "warning",
            confirmButtonColor: "var(--red-color)",
            confirmButtonText: "Ok",
          }).then(async (result) => {
            fetchDataAgendamento(cpf);
          });
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (possuiAviso === true) {
      fetchDataAviso(user?.cpf);
    }
    if (possuiAgendamento === true) {
      fetchDataAgendamento(user?.cpf);
    } else if (possuiAgendamento === false) {
      fetchDataCalendarioDoacoes(user?.cpf);
    }
  }, [
    user?.cpf,
    possuiAviso,
    possuiAgendamento,
    fetchDataAviso,
    fetchDataCalendarioDoacoes,
    fetchDataAgendamento,
  ]);

  if (loading) {
    return (
      <Background>
        <h1>Carregando...</h1>
      </Background>
    );
  } else {
    if (data && data.length === 0) {
      return (
        <Background>
          <Container>
            <h1>Horários disponíveis para Agendamento</h1>
            <strong>{dataDoacao && dataDoacao}</strong>
            <legend>Selecione o horário e confirme:</legend>
            <HorariosContainer>
              <RadioButton
                doador={user}
                idCalendario={idCalendario}
                horariosCalendario={horariosCalendario}
                fetchDataAgendamento={fetchDataAgendamento}
              />
            </HorariosContainer>
            <Footer>
              <p>Local de Doação:</p>
              <a
                style={{
                  textDecoration: "none",
                  color: "gray",
                  fontWeight: "bold",
                  fontSize: "19px",
                  paddingTop: "2.4px",
                }}
                href="https://goo.gl/maps/zmn65JvzUzY8GsYK8"
                target="_blank"
                rel="noreferrer"
              >
                <img src={local} alt="Erro" />
                FIEC I{/* Espaço Bem Viver Mario Jacinto da Silva */}
              </a>
            </Footer>
          </Container>
        </Background>
      );
    } else if (data && data.length > 0) {
      return (
        <>
          <Background>
            <Container>
              <h1>Agendamento Ativo</h1>
              <CompromissoContainer>
                <label htmlFor="donation-date">Dia da Doação</label>
                <InputMask
                  id="donation-date"
                  type="text"
                  mask="99/99/9999"
                  disabled
                  value={format(new Date(data[0].data_doacao), "dd/MM/yyyy")}
                />
                <label htmlFor="donation-hour">Horário da Doação</label>
                <InputMask
                  id="donation-hour"
                  type="text"
                  mask="99:99"
                  disabled
                  value={data[0].horario_doacao}
                />
                <button
                  type="button"
                  onClick={() =>
                    handleCancel(
                      data[0].id_calendario,
                      data[0].cpf_doador,
                      data[0].horario_doacao
                    )
                  }
                >
                  CANCELAR este Agendamento
                </button>
              </CompromissoContainer>
            </Container>
          </Background>
        </>
      );
    } else {
      return <Background></Background>;
    }
  }
};
export default MeuAgendamento;
