import { useEffect, useState } from "react";

import api from "../../services/api";

import {
  GrupoDoadoresContainer,
  Background,
  Content,
  ResponseAgendamentoConteiner,
  ResponseAgendamentoSubConteiner,
  ResponsePreAgendamentoConteiner,
  ResponsePreAgendamentoSubConteiner,
  ResponseFail,
  TitleComponent,
} from "./style.js";

import InputMask from "react-input-mask";

import Modal from "../../Components/Modal/Modal/index.jsx";

import Add from "../../Assets/Images/add.png";
import Delete from "../../Assets/Images/delete.png";

import DoadorInscrito from "../../Assets/Images/doador_inscrito.png";
import DoadorNãoInscrito from "../../Assets/Images/doador_nao_inscrito.png";

import Swal from "sweetalert2";
import Auth from "../../services/auth.js";

const MeuGrupoDoadores = () => {
  const user = Auth.user();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalPreAgendamento, setIsOpenModalPreAgendamento] =
    useState(false);
  const [isOpenModalAgendamento, setIsOpenModalAgendamento] = useState(false);

  const [data, setData] = useState([]);

  const [idCalendario, setIdCalendario] = useState("");
  const [calendarios, setCalendarios] = useState([]);

  const [horarios, setHorarios] = useState([]);
  const [selectedHorarios, setSelectedHorarios] = useState({});

  const [doadores, setDoadores] = useState([{ cpf: "", horario: "" }]);

  const [responseGrupo, setResponseGrupo] = useState({});
  const [responseAgendamento, setResponseAgendamento] = useState({});

  const [statusDoAgendamento, setStatusDoAgendamento] = useState("");
  const [permitirAgendamento, setPermitirAgendamento] = useState(false);
  const [permitirPreAgendamento, setPermitirPreAgendamento] = useState(false);

  function fetchDataCalendars() {
    api
      .get(`/calendars?mult=${true}&cpf_usuario=${user?.cpf}`)
      .then((response) => {
        setCalendarios(response?.data?.calendarios);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    fetchDataCalendars();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function editarData(dataStr, dias) {
    // Divide a data e hora
    const [dataParte, horaParte] = dataStr.split(" - ");

    // Divide a parte da data em dia, mês e ano
    const [dia, mes, ano] = dataParte.split("/").map(Number);

    // Divide a parte da hora em horas e minutos
    const [horas, minutos] = horaParte.split(":").map(Number);

    // Cria um objeto Date no formato correto
    const data = new Date(ano, mes - 1, dia, horas, minutos);

    // Adiciona ou subtrai dias
    data.setDate(data.getDate() + dias);

    // Formata a nova data no formato 'DD/MM/YYYY - HH:MM'
    const novoDia = String(data.getDate()).padStart(2, "0");
    const novoMes = String(data.getMonth() + 1).padStart(2, "0");
    const novoAno = data.getFullYear();
    const novasHoras = String(data.getHours()).padStart(2, "0");
    const novosMinutos = String(data.getMinutes()).padStart(2, "0");

    return `${novoDia}/${novoMes}/${novoAno} - ${novasHoras}:${novosMinutos}`;
  }

  function fetchDataMultiCalendars(id) {
    if (!id) {
      setStatusDoAgendamento("");
      setPermitirAgendamento(false);
      setPermitirPreAgendamento(false);
      return;
    }
    const calendario = calendarios.find(
      (calendario) => calendario?.id === parseInt(id)
    );

    const dataInicio = editarData(calendario?.data_inicio, -4);

    if (
      dataInicio <= new Date().toLocaleDateString() &&
      calendario?.data_termino >= new Date().toLocaleDateString()
    ) {
      setPermitirAgendamento(true);
    } else {
      setPermitirAgendamento(false);
    }

    if (calendario?.data_inicio > new Date().toLocaleDateString()) {
      setStatusDoAgendamento("Aguandando");
      setPermitirPreAgendamento(true);
    } else if (calendario?.data_termino < new Date().toLocaleDateString()) {
      setStatusDoAgendamento("Finalizado");
      setPermitirPreAgendamento(false);
    } else {
      setStatusDoAgendamento("Aberto");
      setPermitirPreAgendamento(true);
    }

    api
      .get(`/schedule_a_donor_group/${id}`)
      .then((response) => {
        if (response) {
          setData(response?.data);

          let existeDoadorSemAgendamento = false;
          response?.data.forEach((doador) => {
            if (doador?.doador_inscrito === null)
              existeDoadorSemAgendamento = true;
          });

          if (!existeDoadorSemAgendamento) {
            setPermitirAgendamento(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setHorarios(
      calendarios?.find(
        (calendario) => calendario?.id === parseInt(idCalendario)
      )?.horarios
    );
    fetchDataMultiCalendars(idCalendario);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idCalendario]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newDoadores = [...doadores];
    const oldHorario = newDoadores[index].horario;

    if (name === "cpf") {
      const duplicate = newDoadores.some(
        (doador, i) => doador.cpf === value && i !== index
      );
      if (duplicate) {
        alert("CPF duplicado não é permitido.");
        return;
      }
    }

    newDoadores[index][name] = value;

    if (name === "horario") {
      if (oldHorario) {
        setSelectedHorarios({
          ...selectedHorarios,
          [oldHorario]: selectedHorarios[oldHorario] - 1,
        });
      }
      setSelectedHorarios({
        ...selectedHorarios,
        [value]: (selectedHorarios[value] || 0) + 1,
      });
    }
    setDoadores(newDoadores);
  };

  const handleOnBlurCpf = async (e, index) => {
    const { value } = e.target;
    if (!value) return;
    try {
      api.get(`/users/?cpf=${value}`).then((response) => {
        doadores[index].nome = response.data.doadores[0].nome;
        setDoadores([...doadores]);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = async (id) => {
    if (!id) return;
    try {
      Swal.fire({
        title: "Deseja cancelar o pré-agendamento?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonColor: "var(--red-color)",
        denyButtonColor: "var(--red-color)",
        confirmButtonText: "Confirmar",
        denyButtonText: "Desistir",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api
            .delete(`/schedule_a_donor_group/${id}`)
            .then((response) => {
              fetchDataMultiCalendars(idCalendario);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmPreAgendamento = async () => {
    try {
      if (!idCalendario || !doadores.length) return;

      const response = await api.post("/schedule_a_donor_group", {
        id_calendario: idCalendario,
        doadores: doadores,
      });

      setResponseGrupo(response.data);
      setDoadores([{ cpf: "", horario: "", nome: "" }]);

      if (response.data?.notInserted.length > 0) {
        setIsOpenModalPreAgendamento(true);
      }

      fetchDataMultiCalendars(idCalendario);
    } catch (error) {
      console.log(error);
    }
  };

  const handleConfirmAgendamento = async () => {
    try {
      if (!idCalendario || !data.length) return;

      Swal.fire({
        title: "Deseja realizar o agendamento?",
        icon: "warning",
        showDenyButton: true,
        confirmButtonColor: "var(--red-color)",
        denyButtonColor: "var(--red-color)",
        confirmButtonText: "Confirmar",
        denyButtonText: "Desistir",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api
            .patch(`/schedule_a_donor_group`, {
              id_calendario: idCalendario,
            })
            .then((response) => {
              setData([{ cpf: "", horario: "", nome: "" }]);

              setIsOpenModalAgendamento(true);
              setResponseAgendamento(response.data);
              fetchDataMultiCalendars(idCalendario);
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Background>
      <Content>
        <div>
          <label htmlFor="select-calendario">Calendário</label>
          <select
            autoFocus
            className="select-calendar"
            name="select-calendario"
            id="select-calendario"
            onChange={(e) => setIdCalendario(e.target.value)}
          >
            <option value="">Selecione um Calendário</option>
            {calendarios?.map((calendario) => (
              <option key={calendario?.id} value={calendario?.id}>
                {calendario?.data_doacao}
              </option>
            ))}
          </select>
        </div>
        {idCalendario ? (
          <table>
            <thead>
              <tr>
                <th>Data da Doação</th>
                <th>Inicio dos Agendamentos</th>
                <th>Termino dos Agendamentos</th>
                <th>Agendamento</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {
                    calendarios.find(
                      (calendario) => calendario.id === parseInt(idCalendario)
                    )?.data_doacao
                  }
                </td>
                <td>
                  {
                    calendarios.find(
                      (calendario) => calendario.id === parseInt(idCalendario)
                    )?.data_inicio
                  }
                </td>
                <td>
                  {
                    calendarios.find(
                      (calendario) => calendario.id === parseInt(idCalendario)
                    )?.data_termino
                  }
                </td>
                <td>{statusDoAgendamento}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <strong>Nenhum Calendário Selecionado</strong>
        )}
        <div>
          <h1>Grupo de Doadores</h1>
          <button
            onClick={() => setIsOpenModal(!isOpenModal)}
            disabled={permitirPreAgendamento ? false : true}
            className="btn-add"
          >
            <img src={Add} alt="Adicionar" />
          </button>
        </div>
        {idCalendario ? (
          <table>
            <thead>
              <tr>
                <th>Agendado</th>
                <th>CPF Doador</th>
                <th>Nome Doador</th>
                <th>Horário Doação</th>
                <th>Excluir</th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  style={
                    item.doador_inscrito
                      ? { backgroundColor: "lightgreen" }
                      : {}
                  }
                >
                  <td>
                    {item.doador_inscrito ? (
                      <img src={DoadorInscrito} alt="Cancelar" />
                    ) : (
                      <img src={DoadorNãoInscrito} alt="Cancelar" />
                    )}
                  </td>
                  <td>{item.cpf_doador}</td>
                  <td>{item.nome_doador}</td>
                  <td>{item.horario_doacao}</td>
                  <td
                    style={
                      item.doador_inscrito
                        ? { cursor: "not-allowed" }
                        : { cursor: "pointer" }
                    }
                    onClick={
                      item.doador_inscrito ? null : () => handleCancel(item.id)
                    }
                  >
                    <img src={Delete} alt="Cancelar" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <strong>Nenhum Calendário Selecionado</strong>
        )}
        {permitirAgendamento && data.length > 0 ? (
          <button type="button" onClick={() => handleConfirmAgendamento()}>
            Realizar o Agendamento do Grupo de Doadores
          </button>
        ) : null}
      </Content>
      <Modal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={() => {
          handleConfirmPreAgendamento();
          setDoadores([...doadores, { cpf: "", horario: "" }]);
        }}
      >
        <TitleComponent>Pré-Agendamentos</TitleComponent>
        <GrupoDoadoresContainer>
          {doadores.map((doador, index) => (
            <div key={index}>
              <div>
                <label>CPF do Doador:</label>
                <InputMask
                  name="cpf"
                  type="text"
                  mask="999.999.999-99"
                  value={doador.cpf}
                  onChange={(e) => handleInputChange(index, e)}
                  onBlur={(e) => handleOnBlurCpf(e, index)}
                  required
                />
              </div>
              <div>
                <label>Nome do Doador:</label>
                <InputMask disabled type="text" value={doador.nome} />
              </div>
              <div>
                <label>Horário da Doação:</label>
                <select
                  name="horario"
                  value={doador.horario}
                  onChange={(e) => handleInputChange(index, e)}
                  required
                >
                  <option value="" disabled>
                    Selecione um horário
                  </option>
                  {horarios &&
                    horarios.map((horario) => (
                      <option
                        key={horario.horario_doacao}
                        value={horario.horario_doacao}
                      >
                        {horario.horario_doacao}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          ))}
          <button type="submit">Confirmar Pré-Agendamento</button>
        </GrupoDoadoresContainer>
      </Modal>
      <Modal
        isOpen={isOpenModalPreAgendamento}
        onClose={() => setIsOpenModalPreAgendamento(false)}
        onSubmit={() => setResponseGrupo({})}
      >
        <TitleComponent>Pré-Agendamento</TitleComponent>
        {responseGrupo?.notInserted?.length ? (
          <ResponsePreAgendamentoConteiner>
            <ResponseFail>Falha</ResponseFail>
            {responseGrupo.notInserted.map((response, index) => (
              <ResponsePreAgendamentoSubConteiner key={index}>
                <div>
                  <strong>CPF do Doador:</strong>
                  <span>{response.cpf}</span>
                </div>
                <div>
                  <strong>Nome do Doador:</strong>
                  <span>{response?.nome}</span>
                </div>
                <div>
                  <strong>Mensagem:</strong>
                  <span>{response.message}</span>
                </div>
              </ResponsePreAgendamentoSubConteiner>
            ))}
          </ResponsePreAgendamentoConteiner>
        ) : null}
      </Modal>
      <Modal
        isOpen={isOpenModalAgendamento}
        onClose={() => setIsOpenModalAgendamento(false)}
        onSubmit={() => setResponseAgendamento({})}
      >
        <TitleComponent>Resposta do Agendamento</TitleComponent>
        {responseAgendamento?.length ? (
          <ResponseAgendamentoConteiner>
            <table>
              <thead>
                <tr>
                  <th>Resultado</th>
                  <th>CPF</th>
                  <th>Doador</th>
                  <th>Mensagem</th>
                </tr>
              </thead>
              <tbody>
                {responseAgendamento.map((response, index) => (
                  <ResponseAgendamentoSubConteiner
                    key={index}
                    style={
                      response.result === "Sucesso"
                        ? { color: "green" }
                        : response.result === "Aviso"
                        ? { color: "#ca8a04" }
                        : { color: "red" }
                    }
                  >
                    <td>{response.result}</td>
                    <td>{response.cpf}</td>
                    <td>{response.nome}</td>
                    <td>{response.message}</td>
                  </ResponseAgendamentoSubConteiner>
                ))}
              </tbody>
            </table>
          </ResponseAgendamentoConteiner>
        ) : null}
      </Modal>
    </Background>
  );
};

export default MeuGrupoDoadores;
