import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import api from "../../services/api";

import { Button, Container, HeaderContainer, TableContainer } from "./style";
import Switch from "../../Components/Switch";
import ToggleSwitch from "../../Components/ToogleSwitch";

const AgendaDoacoes = () => {
  const history = useHistory();
  const search = useLocation().search;

  const [agenda, setAgenda] = useState([]);

  const [selected, setSelected] = useState(0);
  const [searchDonor, setSearchDonor] = useState("");

  useEffect(() => {
    const id = new URLSearchParams(search).get("id");

    if (!id) history.push(`/Agendas`);

    let query = `?id_calendario=${id}`;

    if (searchDonor) {
      if (selected === 0) query += `&cpf=${searchDonor}`;
      else if (selected === 1) query += `&nome=${searchDonor}`;
      console.log(query);
    }

    api
      .get(`/donation-registrations${query}`)
      .then((response) => {
        if (response.data.error) history.push(`/Agendas`);
        else setAgenda(response.data.registrations);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          history.push({ pathname: "/" });
        } else {
          console.error("An error occurred:", error);
        }
      });
  }, [history, search, searchDonor, selected]);

  const handleCheck = async (inscricao) => {
    if (
      inscricao?.id_calendario &&
      inscricao?.cpf &&
      inscricao?.horario_doacao
    ) {
      api
        .patch(`/attended-donation-day`, {
          id_calendario: inscricao?.id_calendario,
          cpf_doador: inscricao?.cpf,
          horario_doacao: inscricao?.horario_doacao,
        })
        .then((response) => {
          if (response.data) {
            let updatedCompareceu;
            if (inscricao?.compareceu === 1) {
              updatedCompareceu = 0;
            } else if (inscricao?.compareceu === 0) {
              updatedCompareceu = 1;
            } else {
              updatedCompareceu = 1;
            }

            setAgenda((prevAgenda) =>
              prevAgenda.map((a) =>
                a.id_calendario === inscricao?.id_calendario &&
                a.cpf === inscricao?.cpf &&
                a.horario_doacao === inscricao?.horario_doacao
                  ? { ...a, compareceu: updatedCompareceu }
                  : a
              )
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <div>
          <Button type="button" onClick={() => history.goBack()}>
            Voltar
          </Button>
          <Switch
            text1="CPF"
            text2="Nome"
            placeholder1="Insira o CPF"
            placeholder2="Insira o Nome"
            search={searchDonor}
            setSearch={setSearchDonor}
            selected={selected}
            setSelected={setSelected}
          />
        </div>
        <div>
          <p></p>
          <Button type="button" onClick={() => window.alert("Em Breve")}>
            Baixar Planilha
          </Button>
        </div>
      </HeaderContainer>
      <TableContainer>
        <thead>
          <tr>
            <td>COMPARECEU</td>
            <td>NOME DOADOR</td>
            <td>CPF DOADOR</td>
            <td>DATA E HORA DA DOAÇÂO</td>
          </tr>
        </thead>
        <tbody>
          {agenda.map((a, i) => (
            <tr key={i}>
              <td data-title="Compareceu">
                <ToggleSwitch
                  isActive={a?.compareceu}
                  onToggle={() => handleCheck(a)}
                />
              </td>
              <td data-title="Nome">{a?.nome}</td>
              <td data-title="CPF">{a?.cpf}</td>
              <td data-title="Data e Hora de Doação">
                {`${new Date(
                  a?.data_doacao.split("T")[0]
                ).toLocaleDateString()} - 
                ${a?.horario_doacao}`}
              </td>
            </tr>
          ))}
        </tbody>
      </TableContainer>
    </Container>
  );
};

export default AgendaDoacoes;
