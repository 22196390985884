import {
  Titulo,
  P,
  Logos,
  Fiec,
  ConfirmCadastro,
  ContainerWhatsApp,
} from "./style";
// import indaituba from "../../Assets/Images/indaiatuba2.png";
import fiec from "../../Assets/Images/fiec.png";
import Footer from "../../Components/Footer/Index";
import { useState, useEffect } from "react";
import Modal from "react-modal";
import api from "../../services/api";
import "./style.css";

import WhatsApp from "../../Assets/Images/whatsapp.jpg";

Modal.setAppElement("#root");
const Home = () => {
  const [dataDoacao, setDataDoacao] = useState("");

  useEffect(() => {
    api.get(`/calendarscheck`).then((response) => {
      if (response.data.error) {
        setDataDoacao(response.data.error.data_doacao);
      }
    });
  }, []);

  return (
    <>
      <Titulo>Seja bem vindo!</Titulo>
      <P>Este site é uma realização de:</P>

      <Logos>
        {/* <Indaituba>
          <img src={indaituba} alt="Erro" />
        </Indaituba> */}
        <Fiec>
          <img src={fiec} alt="Erro" />
        </Fiec>
      </Logos>

      <ContainerWhatsApp>
        <p>
          Receba mais informações sobre da Campanha de Doação de Sangue
          participando do nosso{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://chat.whatsapp.com/E1J5rn0l3PXIQfzpbFbSba"
          >
            Grupo de WhatsApp.
          </a>
        </p>
        <img src={WhatsApp} alt="Grupo do WhatsApp" />
      </ContainerWhatsApp>

      <ConfirmCadastro>
        {/* <h1>Senhas esgotadas para a Doação de {dataDoacao}</h1> */}
        <p>
          ATENÇÃO, apesar de as senhas terem esgotado é possível que algum
          doador realize o cancelamento do agendamento para o dia {dataDoacao}.
          Por isso, acompanhe as senhas disponíveis até o horário limite da
          inscrição.
        </p>
        <p style={{ fontSize: 15, fontWeight: "bold", paddingTop: 20 }}>
          <a
            style={{ color: "black" }}
            href="https://www.fiec.com.br/site/noticia/2088/ver.do"
            target="_blank"
            rel="noreferrer"
          >
            Confira o calendário de doação
          </a>
        </p>
      </ConfirmCadastro>
      <Footer />
    </>
  );
};

export default Home;
