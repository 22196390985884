import styled from "styled-components";
import { Link as Rota } from "react-router-dom";

export const Background = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);

  overflow-y: auto;
  position: fixed;

  background-color: #ffffff;
  background-image: linear-gradient(
    180deg,
    #ffffff 0%,
    #ff6362 50%,
    var(--red-color) 100%
  );

  padding-bottom: 20px;
`;

export const Container = styled.div`
  margin-bottom: 20px;

  overflow-y: auto;
`;

// export const Titulo = styled.h1 `
// text-align: center;
// font-weight: 700;
// margin-top: -190px;
// line-height:130px;
// @media(max-width:1000px){
//     margin-top: -190px;
// }
// `

export const Titulo = styled.h1`
  font-weight: bolder;
  text-align: center;
  padding-bottom: 10px;
  legend {
    color: black;
    font-size: 25px;
  }
  @media (max-width: 1127px) {
    font-size: 25px;
    text-align: center;
  }
`;

export const ContentForm = styled.div`
  width: 55%;
  height: 850px;

  background: #fff;

  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  position: absolute;
  top: 4%;

  left: 50%;
  transform: translate(-50%);
  float: right;

  @media (max-width: 1510px) {
    width: 75%;
  }
  @media (max-width: 1000px) {
    min-height: 1405px;
  }

  @media (max-width: 470px) {
    width: 91%;
    min-height: 1485px;
  }

  @media (max-width: 394px) {
    width: 99%;
  }

  @media (max-width: 260px) {
    min-height: 1535px;
  }
`;

export const Form = styled.form`
  display: flex;
`;

export const FormController = styled.div`
  margin-top: 0.5rem;
  width: 85%;

  &:last-child {
    display: flex;
    margin-top: 0.5rem;
    align-items: center;
  }
`;

export const Link = styled(Rota)`
  text-decoration: none;
  color: red;

  img {
    width: 45px;
    height: 80px;
    margin-top: 1%;
    margin-left: 50px;
    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }

    @media (max-width: 810px) {
      margin-left: 25px;
      width: 40px;
    }

    @media (max-width: 656px) {
      display: none;
      /* margin-left: 22px;
        width:30px;
        height: 40px;
        margin-top: 25px; */
    }
  }
`;

export const Input = styled.input`
  display: flex;
  flex-direction: row;
  background-color: #d0d0d0;
  border: none;
  border-radius: 20px;
  height: 30px;
  width: 334px !important;
  text-align: center;

  @media (max-width: 369px) {
    width: 260px !important;
    font-size: 12px;
  }
`;

export const Select = styled.select`
  display: flex;
  background-color: #d0d0d0;
  border: none;
  border-radius: 15px;
  height: 30px;
  width: 334px !important;
  text-align: center;
  font-weight: 600;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 11.9px;
  margin-right: 19px;
  margin-top: 0;

  @media (max-width: 369px) {
    width: 260px !important;
    font-size: 12px;
  }
`;

export const Label = styled.label`
  margin-left: 1rem;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: -80px;
  align-items: center;
  height: 80px;
  width: 100%;

  button {
    margin-top: 20px;
    height: 43px;
    width: 109px;
    font-weight: bolder;
    background: var(--red-color);
    border: 0;
    border-radius: 0.25rem;
    padding: 0.6rem 0.5rem;
    color: #fff;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      opacity: 90%;
      transform: scale(1.1);
    }
    @media (max-width: 1000px) {
      margin-top: 14px;
    }
  }
`;

export const DivSelect = styled.div`
  margin-top: 42px;
  margin-bottom: 20px;
  margin-left: 15px;
  display: flex;
  align-items: center;

  @media (max-width: 1000px) {
    margin-top: 22px;
    margin-bottom: 10px;
  }
`;

export const SelectEstados = styled.select`
  scroll-behavior: smooth;
  width: 60px;
  margin-left: 2rem;
  box-shadow: 1px 1.5px 6px gray;
  height: 24px;
  display: flex;
  text-align-last: center;
  text-align: center;
  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: gray;
    border: 2px solid transparent;
    background-clip: content-box; /* THIS IS IMPORTANT */
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: gray;
    border: 1px solid #8d8d8d;
  }
`;

export const DivDireita = styled.div`
  width: 50%;
  position: relative;
  padding-left: 11px;
  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const DivEsquerda = styled.div`
  width: 50%;
  padding-left: -11px;
  position: relative;
  @media (max-width: 1000px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

export const DivCentral = styled.div`
  display: flex;
  position: relative;
  justify-content: center;

  @media (max-width: 1000px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const Hr = styled.div`
  background-color: red;
  height: 1.2px;
  width: 60%;
  border-radius: 30px;
  margin-top: 5px;
  margin-left: 5px;
`;
