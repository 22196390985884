import React from "react";
import { useHistory } from "react-router-dom";

import { TableContainer, TableBody, TableHead } from "./styles";
import {
  IoEyeOutline,
  IoCreate,
  IoTrashOutline,
  IoCheckbox,
} from "react-icons/io5";

export default function Table({
  list,
  data,
  handleClicked,
  handleEdit,
  handleDelete,
}) {
  const history = useHistory();

  return (
    <TableContainer>
      <TableHead>
        <tr>
          {list.map((l, i) => (
            <th key={i} style={{ textAlign: "center" }}>
              {l.replace(/_/g, " ")}
            </th>
          ))}
          <th style={{ width: 120, textAlign: "center" }}>Relatório</th>
          <th style={{ width: 120, textAlign: "center" }}>Doações</th>
          <th style={{ width: 120, textAlign: "center" }}>Editar</th>
          <th style={{ width: 120, textAlign: "center" }}>Excluir</th>
        </tr>
      </TableHead>
      <TableBody>
        {data.map((d, i) => (
          <tr key={i}>
            <td data-title="Data Doação">{d.data_doacao}</td>
            <td data-title="Data Início">{d.data_inicio}</td>
            <td data-title="Data Término">{d.data_termino}</td>
            <td data-title="Relatório">
              <IoEyeOutline
                color="blue"
                onClick={() => {
                  handleClicked(d.id);
                }}
              />
            </td>
            {new Date(d?.data_doacao) !== new Date() ? (
              <td
                onClick={async () => {
                  history.push(`/AgendaDoacoes?id=${d?.id}`);
                }}
              >
                <IoCheckbox color="black" />
              </td>
            ) : (
              <td
                style={{ cursor: "not-allowed" }}
                title="O período para realização da ação já se encerrou ou/e não chegou no dia da doação."
              >
                <IoCheckbox color="black" />
              </td>
            )}
            {d?.data_inicio > new Date().toLocaleDateString() &&
            d.horarios.some((horario) => horario.vagas_preenchidas === 0) ? (
              <td
                onClick={async () => {
                  await handleEdit(d);
                }}
              >
                <IoCreate color="black" />
              </td>
            ) : (
              <td
                style={{ cursor: "not-allowed" }}
                title="O período para realização da ação já se encerrou ou/e já existe um doador inscrito."
              >
                <IoCreate color="black" />
              </td>
            )}
            {d?.data_inicio > new Date().toLocaleDateString() &&
            d.horarios.some((horario) => horario.vagas_preenchidas === 0) ? (
              <td
                data-title="Excluir"
                onClick={async () => {
                  await handleDelete(d.id);
                }}
              >
                <IoTrashOutline color="red" />
              </td>
            ) : (
              <td
                data-title="Excluir"
                style={{ cursor: "not-allowed" }}
                title="O período para realização da ação já se encerrou ou/e já existe um doador inscrito."
              >
                <IoTrashOutline color="red" />
              </td>
            )}
          </tr>
        ))}
      </TableBody>
    </TableContainer>
  );
}
